<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 250px;" @change="getAllCinemaList">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 250px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="name" label="影片名称">
						<a-input v-model:value="formState.name" placeholder="请输入影片名称"></a-input>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">

					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="orderId" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 1100 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'image'">
							<a-image style="width: 60px;" :src="record.imageUrl"></a-image>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['ciname_basis_film_update']" type="link" @click="onEdit(record)">编辑影片信息</a-button>
							<a-button v-permission="['ciname_basis_film_update']" type="link" @click="onPull(record)">更新影片信息</a-button>
						</template>
					</template>
				</a-table>
			</div>

			<a-modal width="850px" v-model:visible="showModal" title="更换影片海报" @ok="onSubmit" @cancel="onCancel">
				<a-form ref="addForm" name="addForm" :model="modelRef" :label-col="{span: 4}">
					<a-form-item label="影片名称">{{ editData.name }}</a-form-item>

					<a-form-item label="影片类型" name="filmType">
						<a-input v-model:value="modelRef.filmType" placeholder="请输入影片类型"></a-input>
					</a-form-item>

					<a-form-item label="导演" name="directedBy">
						<a-input v-model:value="modelRef.directedBy" placeholder="请输入导演"></a-input>
					</a-form-item>

					<a-form-item label="演员" name="mainStars">
						<a-input v-model:value="modelRef.mainStars" placeholder="请输入演员"></a-input>
					</a-form-item>

					<a-form-item label="海报上传" name="imgUrl" :rules="[{required: true, message: '必填项不允许为空'}]">
						<div style="height: 130px;">
							<a-upload v-model:file-list="fileList" name="file" list-type="picture-card"
								class="avatar-uploader" action="/admin/ajaxUpload.do" :before-upload="beforeUpload"
								@change="handleChange">
								<div v-if="fileList.length < 1">
									<div class="ant-upload-text">上传</div>
								</div>
							</a-upload>
						</div>
					</a-form-item>

					<a-form-item label="影片简介" name="synopsis">
						<a-textarea v-model:value="modelRef.synopsis" placeholder="请输入影片简介"></a-textarea>
					</a-form-item>
				</a-form>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getOrganizationList, getCinemaList, getFilmList, updateFilmImg, pullMovie } from '@/service/modules/cinema.js';
	export default {
		components: {Icon },
		data() {
			return {
				loading: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				formState: {
					couponType: 1,
					organizationId: 0,
				},
				searchData: {},
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '影片编码',
					dataIndex: 'standardCode'
				},{
					title: '影片名称',
					dataIndex: 'name'
				}, {
					title: '海报',
					key: 'image',
					width: 120
				}, {
					title: '影片类型',
					dataIndex: 'filmType'
				}, {
					title: '导演',
					dataIndex: 'directedBy'
				}, {
					title: '演员',
					dataIndex: 'mainStars'
				}, {
					title: '影片简介',
					dataIndex: 'synopsis'
				}, {
					title: '操作',
					key: 'action'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				showModal: false,
				modelRef: {},
				editData: {},
				fileList: []
			}
		},
		created() {
			this.getOrganizationList();
			this.onSearch();
		},
		methods: {
			onBack() {
				this.showModal = false;
			},
			onSearch() {
				this.pagination.current = 1;
				// this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSubmit() {
				this.$refs.addForm.validateFields().then(async () => {
					this.loading = true;
					try {
						let postData = {
							id: this.editData.id,
							imageUrl: this.modelRef.imgUrl,
							...this.modelRef
						}
						delete postData.imgUrl;
						let ret = await updateFilmImg(postData);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.$refs.addForm.resetFields();
							this.showModal = false;
							this.fileList = [];
							this.modelRef = {};
							this.getData();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			onCancel() {
				this.$refs.addForm.resetFields();
				this.fileList = [];
				this.modelRef = {};
				this.showModal = false;
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getFilmList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
      onPull(item) {
        this.loading = true;
        pullMovie({id: item.id}).then((res)=>{
          this.loading = false;
          if (res.code == 200) {
            this.$message.success('更新成功');
          }
        }).catch(err=>{
          this.loading = false;
        });
      },
			onEdit(item) {
				this.editData = item;
				if (item.imageUrl) {
					this.fileList = [{
						url: item.imageUrl,
						name: item.imageUrl,
						uid: 0
					}];
				}
				this.modelRef = {
					imgUrl: item.imageUrl,
					filmType: item.filmType,
					directedBy: item.directedBy,
					mainStars: item.mainStars,
					synopsis: item.synopsis
				};
				this.showModal = true;
			},
			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					this.$message.error('上传文件格式不正确');
				}
				const isLt1M = file.size / 1024 / 1024 < 2;
				if (!isLt1M) {
					this.$message.error('上传文件太大了');
				}
				return isJpgOrPng && isLt1M;
			},
			handleChange(info) {
				if (!info.file.status) {
					this.fileList.splice(this.fileList.length - 1, 1);
				}
				if (info.file.status === 'done') {
					this.modelRef.imgUrl = '';
					const $fileList = [...info.fileList];
					$fileList.map(file => {
						if (file.response) {
							this.modelRef.imgUrl = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
